import styled from "styled-components";
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import theme from "../Helper/Theme";
import { useState, useEffect } from "react";
import { AboutMeButton } from "../Landing/Landing";
import * as constants from '../Helper/Constants';
import { mobileCheck } from "../Helper/Helper";

const HeaderWrapper = styled.div`
    position: ${({ fixed }) => fixed ? 'fixed' : 'absolute'};
    top: 0;
    left: 0;
    height: auto;
    background-color: ${({ fixed }) => fixed ? "#444" : 'transparent'};
    color: ${theme.colors.white};
    z-index: 2;
    width: ${({ width }) => width + "px"};
    padding: 25px;
`;

//socials
const SocialWrapper = styled.div`
    display: flex;
`;
const GitHub = styled(FaGithub)`
    font-size: 30px;
    margin-right: 25px;
    cursor: pointer;
`;
const Linkedin = styled(FaLinkedin)`
    font-size: 30px;
    margin-right: 25px;
    cursor: pointer;
`;
export const EmailLinkWrapper = styled.a`
    text-decoration: none;
    color: ${theme.colors.white};
`;
const Email = styled(AiOutlineMail)`
    font-size: 30px;
    cursor: pointer;
`;
export const HeaderContactButton = styled(AboutMeButton)`
    position: absolute;
    right: 150px;
    top: 20px;
    font-size: 16px;
    color: #444;
    font-weight: 600;
    border: 1px solid #fff;
    background-color: #fff;
`;
const ContactPicture = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 500px;
    position: absolute;
    right: 80px;
    top: 18px;
    @media (min-width:1200px) {
        display: block;
    }
    @media (max-width:1199px) {
        display: none;
    };
`;

const Header = () => {

    const [headerFixed, setHeaderFixed] = useState(false);
    const [showContactButton, setShowContactButton] = useState(false);
    const [width, setWidth] = useState(0);

    const gitHubHandler = () => window.open("https://github.com/bitcoin21m", "_blank");
    const linkedInHandler = () => window.open("https://www.linkedin.com/in/bperrydeveloper/", "_blank");

    useEffect(() => {
        document.addEventListener('scroll', (e) => {
          setHeaderFixed(window.pageYOffset > 50);
          setShowContactButton(window.pageYOffset > 50);
        });

        setWidth(window.outerWidth - 50);
      }, []);

    return (
        <HeaderWrapper width={width} fixed={headerFixed}>
            <SocialWrapper>
                <GitHub title={'GitHub'} onClick={gitHubHandler} />
                <Linkedin title={'LinkedIn'} onClick={linkedInHandler} />
                <EmailLinkWrapper title={'Email'} fixed={headerFixed} href="mailto:cryptanon21@protonmail.com?Subject=Let's Chat!"><Email type="email" /></EmailLinkWrapper>
                { showContactButton && 
                    <>
                        { mobileCheck()
                            ?
                                <EmailLinkWrapper fixed={headerFixed} href="mailto:cryptanon21@protonmail.com?Subject=Let's Chat!">
                                    <HeaderContactButton className={'contact_btn'} position={'fixed'} fieldName={constants.FIELDNAME_CONTACT} color={theme.colors.buttons.contact} type={'input'} name={constants.BUTTON_CONTACT_VALUE} />
                                </EmailLinkWrapper>
                            :
                                <EmailLinkWrapper fixed={headerFixed} href="mailto:cryptanon21@protonmail.com?Subject=Let's Chat!">
                                    <HeaderContactButton className={'contact_btn'} position={'fixed'} fieldName={constants.FIELDNAME_CONTACT} color={theme.colors.buttons.contact} type={'input'} name={constants.BUTTON_CONTACT_VALUE}/>
                                </EmailLinkWrapper>
                        }
                        
                        <ContactPicture src={'resume_picture.jpeg'} />
                    </>
                }
            </SocialWrapper>
        </HeaderWrapper>
    );
};

export default Header;