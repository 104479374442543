import styled from "styled-components"

const SectionWrapper = styled.div`
    padding: 50px;
`;

const Section = ({children, className}) => {
    return (
        <SectionWrapper className={className}>
            {children}
        </SectionWrapper>
    );
};

export default Section;