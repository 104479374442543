import { useEffect } from 'react';
import './App.css';
import Contact from './Content/Contact';
import Description from './Content/Description';
import Portfolio from './Content/Portfolio';
import Header from './Header/Header';
import Landing from './Landing/Landing';
import axios from 'axios';

function App() {

  useEffect(() => {
    axios.get('https://api.brianperry-dev.io/hits.php').then(res => {
      console.log(res)
    });

    document.querySelector("body").style.width = window.outerWidth + "px";
  }, [])

  const contactHandler = () => {
    console.log("email copied to clipboard")
  }

  return (
    <>
      <Header contactHandler={contactHandler} />
      <Landing contactHandler={contactHandler} />
      <Description />
      <Portfolio />
      {/* <Contact contactHandler={contactHandler} /> */}
    </>
  );
}

export default App;
