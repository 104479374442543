import styled from "styled-components";
import theme from "../../Helper/Theme";

export const ButtonWrapper = styled.div``;
const Btn = styled.button`
    font-size: ${theme.fontSize.button};
    color: ${theme.colors.white};
    border-radius: 8px;
    border: 1px solid ${({color}) => color};
    &:hover {
        box-shadow: 0px 0px 10px ${({color}) => color};
    };
    background-color: ${({color}) => color};
    padding: 15px;
    cursor: pointer;
    ${({ position }) => position === 'fixed' ? 'position: fixed; right: 100px; top: 20px;' : 'position: inherit'};
    @media (max-width: 1199px) {
        ${({ position }) => position === 'fixed' && 'display: none;'};
    };
    font-family: 'Raleway', sans-serif;
`;

const Button = ({ fieldName, className, type, name, position, color, onClick, onHover }) => {
    return (
        <Btn 
            type={type} 
            color={color}
            fieldName={fieldName} 
            className={className} 
            value={name} 
            position={position} 
            onClick={onClick} 
            onHover={onHover}>
            {name}
        </Btn>
    );
};

export default Button;