import Section from "./Section";
import data from '../Data/data.json';
import Project from "./Project";
import styled from "styled-components";
import theme from "../Helper/Theme";

const PortfolioTitle = styled.p`
    font-family: 'Raleway', sans-serif;
    font-size: 25px;
    text-align: left;
    letter-spacing: 15px;
    font-weight: bold;
    border-top: 2px solid ${theme.colors.buttons.contact};
    margin: 0 auto 75px auto;
    padding-top: 10px;
    color: ${theme.colors.buttons.contact};
`;

const Portfolio = () => {
    return (
        data.Projects.map((d,i) => {
            return (
                <Section className={`portfolio-section-${i}`} key={`${d}_${i}`}>
                    { i ===0 && <PortfolioTitle>Portfolio</PortfolioTitle> }
                    <Project data={d} />
                </Section>
            );
        })
    );
};

export default Portfolio;