import styled from "styled-components";
import Input, { InputWrapper } from "../UIComponents/Input/Input";
import Section from "./Section";

const ContactFormWrapper = styled.div`
    text-align: center;
    display: flex;
    justify-content: space-evenly;
`;
const ContactHeader = styled.p`
    text-align: center;
`;
const ContactForm = styled.div``;

//section
const ContactSection = styled(Section)``;

const Contact = () => {
    return (
        <ContactSection>
            <ContactHeader>
                If you would like to contact me to discuss your needs and how I can be of help, please use the form below
            </ContactHeader>
            <ContactFormWrapper>
                <ContactForm>
                    <Input placeholder={'First name'} type={'text'} fieldName={'name'} /><br/><br/>
                    <Input placeholder={'First name'} type={'text'} fieldName={'name'} /><br/><br/>
                    <Input placeholder={'First name'} type={'text'} fieldName={'name'} /><br/><br/>
                    <Input placeholder={'First name'} type={'text'} fieldName={'name'} /><br/><br/>
                    <Input placeholder={'First name'} type={'text'} fieldName={'name'} /><br/><br/>
                    <Input placeholder={'First name'} type={'text'} fieldName={'name'} /><br/><br/>
                    <Input placeholder={'First name'} type={'text'} fieldName={'name'} /><br/><br/>
                </ContactForm>
            </ContactFormWrapper>
        </ContactSection>
    );
};

export default Contact;