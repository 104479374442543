import styled from "styled-components";
import Section from "./Section";
import data from '../Data/data.json';
import theme from "../Helper/Theme";
import { EmailLinkWrapper, HeaderContactButton } from "../Header/Header";

const DescriptionWrapper = styled.div`
    margin: 0 auto;
    text-align: left;
`;
const DescriptionTitle = styled.p`
    font-family: 'Raleway', sans-serif;
    font-size: 25px;
    font-weight: bold;
    margin: 1% auto 50px auto;
    letter-spacing: 15px;
    border-top: 2px solid ${theme.colors.buttons.contact};
    color: ${theme.colors.buttons.contact};
    padding-top: 10px;
`;
const DescriptionContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const DescriptionSkills = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (min-width:1200px) {
        width: 60%;
    }
    @media (max-width:1199px) {
        width: 100%;
    }
`;
const Skill = styled.p`
    color: #fff;
    background-color: #444;
    margin-right: 10px;
    height: fit-content;
    border-radius: 5px;
    padding: 10px;
`;
const DescriptionContent = styled.div`
    font-size: 16px;
    letter-spacing: 1.2px;
    font-family: inherit;
    text-align: left;
    @media (min-width:1200px) {
        margin: 0 300px;
    }
    @media (max-width:1199px) {
        margin: 0 auto;
    }
`;
const DescriptionContentParagraphs = styled.p`
    line-height: 30px;
    margin-bottom: 35px;
`;
const ContactButtonWrapper = styled(EmailLinkWrapper)`
    text-align: center;
    margin: 50px auto 0 auto;
`;
const ContactButton = styled(HeaderContactButton)`
    display: inline;
    position: inherit;
    border: 1px solid #009688;
    background-color: #009688;
    color: #fff;
    font-size: 25px;
`;

const Description = () => {
    return (
        <Section className={'about_me_section'}>
            <DescriptionWrapper>
                <DescriptionTitle>About Me</DescriptionTitle>
                <DescriptionContentWrapper>
                    <DescriptionSkills>
                        {
                            data.Skills.map((s, i) => {
                                return <Skill>{s}</Skill>
                            })
                        }
                    </DescriptionSkills>
                    <DescriptionContent>
                        {
                            data.Biography.map((p, i) => {
                                return <DescriptionContentParagraphs key={`${p}_${i}`}>{p.p}</DescriptionContentParagraphs>
                            })
                        }
                    </DescriptionContent>
                    <ContactButtonWrapper href="mailto:cryptanon21@protonmail.com?Subject=Let's Chat!">
                        <ContactButton 
                            className={'contact_btn'} 
                            color={'#009688'} 
                            fieldName={'contact_button'} 
                            name={'Let\'s Work Together'} 
                            type={'button'}
                        />
                    </ContactButtonWrapper>
                </DescriptionContentWrapper>
            </DescriptionWrapper>
        </Section>
    );
};

export default Description;