import styled from "styled-components";

const ProjectWrapper = styled.div`
    display: flex;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 50px;
    align-items: center;
    @media (min-width:1200px) {
        flex-direction: ${({ id }) => id % 2 === 0 ? 'row' : 'row-reverse'};
        margin: 0 300px;
    };
    @media (max-width:1199px) {
        flex-direction: column;
        margin: 0 auto;
        text-align: center;
    };
`;
const ProjectDetails = styled.div`
    width: 100%;
    @media (min-width:1200px) {
        margin-left: ${({ id }) => id % 2 !== 0 && '40px'};
    };
    @media (max-width:1199px) {
        margin-left: 0;
    };
`;
const ProjectImageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
`;
const ProjectImage = styled.img`
    width: 100%;
`;
const ProjectTitle = styled.p`
    font-size: 45px;
    margin: 0 auto 15px 0;
    font-family: 'Raleway', sans-serif;
`;
const ProjectWebsite = styled.a`
    color: #009688;
    cursor: pointer;
`;
const ProjectDescription = styled.pre`
    letter-spacing: 1.2px;
    line-height: 2;
    font-size: 15px;
    font-family: inherit;
    white-space: break-spaces;
    margin-top: 25px;
    @media (min-width:1200px) {
        margin-right: 50px;
    };
    @media (max-width:1199px) {
        margin-right: 0;
    };
`;

const Project = ({ data, className }) => {

    const { website, url, images, description, id } = data;
    return (
        <ProjectWrapper id={id} className={className}>
            <ProjectDetails>
                <ProjectTitle>{website}</ProjectTitle>
                <ProjectWebsite target={'_blank'} href={url}>{url}</ProjectWebsite>
                <ProjectDescription>{description}</ProjectDescription>
            </ProjectDetails>
            <ProjectImageWrapper>
                {
                    images.map((img, i) => {
                        return (
                            <>
                                <ProjectImage
                                    key={`${img}_${i}`}
                                    src={img} 
                                />
                                <div style={{ backgroundColor: '#e8e8e8', width: '1px', height: "auto" }}></div>
                            </>
                        )
                    })
                }
            </ProjectImageWrapper>
        </ProjectWrapper>
    );
};

export default Project;