import theme from '../Helper/Theme';
import styled from 'styled-components';
import Button, { ButtonWrapper } from '../UIComponents/Button/Button';
import * as constants from '../Helper/Constants';
import { EmailLinkWrapper } from '../Header/Header';
import { mobileCheck } from '../Helper/Helper';

//video
const VideoWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: sticky;
    @media (min-width:1200px) {
        overflow-x: hidden;
    }
    @media (max-width:1199px) {
        overflow-x: auto;
        width: fit-content;
    }
`;
const Video = styled.video`
    @media (min-width:1200px) {
        width: 100vw;
    }
    @media (max-width:1199px) {
        width: fit-content;
    }
`;
const VideoSource = styled.source``;

//title
const LandingTitleWrapper = styled.div`
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: center;
    
    @media (min-width:1200px) {
        top: 25%;
    }
    @media (max-width:1199px) {
        top: 35%;
        bottom: 0;
        width: auto;
        margin: auto;
        position: absolute;
        left: 7%;
    }
`;
const LandingTitle = styled.h1`
    color: ${theme.colors.white};
    text-transform: uppercase;
    letter-spacing: 20px;
    margin-bottom: 0;
    font-family: monospace;
    @media (min-width:1200px) {
        font-size: ${theme.fontSize.landingTitle};
    }
    @media (max-width:1199px) {
        font-size: ${theme.fontSize.mobile.landingTitle};
    };
    font-family: 'Raleway', sans-serif;
`;
const LandingSubTitle = styled.p`
    color: ${theme.colors.white};
    letter-spacing: 3px;
    font-family: monospace;
    text-transform: lowercase;

    @media (min-width:1200px) {
        font-size: ${theme.fontSize.landingSubTitle};
    }
    @media (max-width:1199px) {
        font-size: ${theme.fontSize.mobile.landingSubTitle};
    }
`;

//buttons
const AboutMeWrapper = styled(ButtonWrapper)`
    @media (min-width:1200px) {
        margin-top: 80px;
    }
    @media (max-width:1199px) {
        margin-top: 30px;
    }
`;
export const AboutMeButton = styled(Button)`
    letter-spacing: 1.2px;
    font-size: 20px;
`;

const Landing = () => {

    const goToPortfolio = () => {
        const firstProject = document.querySelector("[class*='about_me_section']");
        console.log(firstProject.scrollHeight);
        firstProject.scrollIntoView({
            behavior: 'smooth',
        });
    }

    return (
        <>
            <VideoWrapper id="video-wrapper">
                <Video autoPlay={true} muted loop>
                    <VideoSource src='intro-video.mp4' type='video/mp4' />
                    <VideoSource src='intro-video.ogg' type='video/ogg' />
                </Video>
            </VideoWrapper>
            <LandingTitleWrapper>
                <LandingTitle>
                    brian perry
                </LandingTitle>
                <LandingSubTitle>
                    Freelance Software Developer
                </LandingSubTitle>
                <AboutMeWrapper>
                { mobileCheck()
                    ?
                        <EmailLinkWrapper href="mailto:cryptanon21@protonmail.com?Subject=Let's Chat!">
                            <AboutMeButton fieldName={constants.FIELDNAME_CONTACT} color={theme.colors.buttons.contact} type={'input'} name={constants.BUTTON_CONTACT_VALUE}/>
                        </EmailLinkWrapper>
                    :
                        <AboutMeButton className={'about_me_button'} fieldName={constants.FIELDNAME_CONTACT} color={theme.colors.buttons.contact} type={'input'} name={constants.BUTTON_ABOUT_ME_VALUE} onClick={goToPortfolio} />
                }
                </AboutMeWrapper>
            </LandingTitleWrapper>
        </>
    );
};

export default Landing;